var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex  flex-column"},_vm._l((_vm.games),function(game){return _c('router-link',{key:game.ID,staticClass:"py-2 game-border-bottom tour-link",class:_vm.dataSport.kindOfSport + '_border',attrs:{"to":_vm.dataSport.kindOfSport + '/tour/' + _vm.tourid}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-shrink-1 align-self-center text-left game-time-block"},[_c('div',{staticClass:"font-weight-normal time-start-game text-white"},[_vm._v(" "+_vm._s(game.time)+" ")]),_c('MlbGameState',{attrs:{"state":game.state,"dataSport":_vm.dataSport}})],1),_c('div',{staticClass:"pl-2 w-100"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"text-right w-100"},[_c('div',{staticClass:"font-weight-normal team-font-size text-white",domProps:{"textContent":_vm._s(
                  _vm.$i18n.locale === 'ru'
                    ? game.team_side_1_title
                    : game.team_side_1_title_eng
                )}})]),_c('MlbGameScore',{attrs:{"game":game,"dataSport":_vm.dataSport}}),_c('div',{staticClass:"text-left w-100"},[_c('div',{staticClass:"font-weight-normal team-font-size text-white",domProps:{"textContent":_vm._s(
                  _vm.$i18n.locale === 'ru'
                    ? game.team_side_2_title
                    : game.team_side_2_title_eng
                )}})])],1),_c('div',{staticClass:"periods-block text-center"},[_c('small',{staticClass:"text-nowrap"},[_vm._v(_vm._s(game.Periods))])])])])])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }